import React, {useState, useEffect } from 'react';
import {Form, Button, Row, Col, Input, Select, DatePicker, Radio, Drawer} from 'antd';
import moment from 'moment';
import api from '../../server/api';
import TableBox from '../../container/Table';
import UserManagement from '../../container/LevelConfig';
import Info from './Info';
import './index.scss';

const {Option} = Select;

const Order = props => {
    const columns = [
        {
            title: '订单来源',
            dataIndex: 'source',
            fixed: 'left',
            render: text => text === '1' ? '官网' : '小程序',
        },
        {
            title: '会员等级',
            dataIndex: 'level_name',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '购买年限',
            dataIndex: 'year',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '订单号',
            dataIndex: 'order_no',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '用户ID',
            dataIndex: 'user_id',
            render: text => text,
        },
        {
            title: '用户手机号',
            dataIndex: 'cellphone',
            render: text => text,
        },
        {
            title: '下单时间',
            dataIndex: 'create_time',
            width: 120,
            render: text => <span>{moment(text*1000).format('YYYY-MM-DD')}<br />{moment(text*1000).format('hh:mm:ss')}</span>,
        },
        {
            title: '支付时间',
            dataIndex: 'name7',
            render: text => <span>{moment(text).format('YYYY-MM-DD')}<br />{moment(text).format('hh:mm:ss')}</span>,
        },
        {
            title: '会员价格',
            dataIndex: 'level_amount',
            render: text => text,
        },
        {
            title: '订单金额',
            dataIndex: 'amount',
            render: text => text,
        },
        {
            title: '优惠金额',
            dataIndex: 'coupon_amount',
            render: text => text,
        },
        {
            title: '实付金额',
            dataIndex: 'name11',
            render: text => text,
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            render: text => text === '10' ? '待付款' : 
            text === '20' ? '待审核' :
            text === '40' ? '已完成' : '已取消',
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            fixed: 'right',
            render: (text, row) => <Button type="link" onClick={openInfo.bind(this, row)}>详情</Button>,
        },
    ]
    
    const Seach = () => {
        const PropsSeach = props.Seach;
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
            {PropsSeach || <Row>
                <Col span={6}>
                    <Form.Item
                        label="&#12288;订单号"
                        name="order_no"
                    >
                        <Input placeholder="请输入订单号" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <UserManagement
                        label="会员等级"
                        name="level"
                        placeholder="请选择会员等级"
                    />
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="用户信息"
                        name="user_where"
                    >
                        <Input placeholder="请输入用户名或手机号" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="下单时间"
                        name="create_start"
                    >
                        <DatePicker placeholder="请选择下单时间" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="购买年限"
                        name="year"
                    >
                        <Select placeholder="请选择购买年限">
                            <Option value={0}>请选择</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="订单来源"
                        name="source"
                    >
                        <Select placeholder="请选择订单来源">
                            <Option value={1}>网站</Option>
                            <Option value={2}>小程序</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        label="订单状态"
                        name="status"
                    >
                        <Radio.Group defaultValue="a" buttonStyle="solid">
                            <Radio.Button value={undefined}>全部订单</Radio.Button>
                            <Radio.Button value={11}>待付款-线下付款</Radio.Button>
                            <Radio.Button value={20}>待付款-转账待审核</Radio.Button>
                            <Radio.Button value={40}>已完成</Radio.Button>
                            <Radio.Button value={100}>已取消</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item>
                        <Button style={{float: 'right'}} type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                </Col>
            </Row>}
        </Form>)
    }

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [infoData, setinfoData] = useState({});
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [infoVisible, setinfoVisible] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true)
        api.orderList({page:current, user_where: props.userId, ...parameter}).then(res => {
            setTotal(res.total);
            setData(res.list);
            setloading(false)
        })
    },[props, current, parameter])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        setParameter(values)
    }

    const openInfo = row => {
        api.orderinfo({order_no: row.order_no}).then(res => {
            setinfoData(res)
            setinfoVisible(true);
        })
    }

    const closeInfo = () => {
        setinfoVisible(false);
    }

    const upList = () => {
        api.orderList({page:current, user_where: props.userId, ...parameter}).then(res => {
            setTotal(res.total);
            setData(res.list);
            setloading(false)
        })
    }
    
    return (<>
        <TableBox
            Seach={Seach}
            total={total}
            columns={columns}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />
        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeInfo}
            visible={infoVisible}
            width={'60%'}
        >
            <Info data={infoData} upList={upList} />
        </Drawer>
    </>)
}


 
export default Order;