import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col, Input, Select, Drawer, DatePicker} from 'antd';
import moment from 'moment';
import TableBox from '../../container/Table';
import Info from './Info';
import api from '../../server/api';
import UserManagement from '../../container/LevelConfig';
import './index.scss';

const {Option} = Select;
const {RangePicker} = DatePicker;

const User = props => {
    const columns = [
        {
            title: '用户来源',
            dataIndex: 'source_str',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '会员等级',
            dataIndex: 'level_name',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '用户ID',
            dataIndex: 'user_id',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '用户手机号',
            dataIndex: 'cellphone',
            render: text => text,
        },
        {
            title: '微信绑定',
            dataIndex: 'weixin_code',
            render: text => !text ? '否' : '是',
        },
        {
            title: '注册时间',
            dataIndex: 'create_time',
            render: text => moment(text*1000).format('YYYY-MM-DD'),
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => text === "1" ? '正常' : '注销',
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            width: 220,
            fixed: 'right',
            render: (text, row) => (<>
                <Button type="link" onClick={openInfo.bind(this, row)}>详情</Button>
            </>),
        },
    ]
    
    const Seach = () => {
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
            <Row>
                <Col span={6}>
                    <Form.Item
                        label="用户信息"
                        name="user_where"
                    >
                        <Input placeholder="请输入ID/手机号" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <UserManagement
                        label="会员等级"
                        name="level"
                        placeholder="请选择会员等级"
                    />
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="用户来源"
                        name="source"
                    >
                        <Select placeholder="请选择用户来源" allowClear>
                            <Option value={1}>官网</Option>
                            <Option value={2}>小程序</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="用户状态"
                        name="status"
                    >
                        <Select placeholder="请选择用户状态" allowClear>
                            <Option value={1}>正常</Option>
                            <Option value={2}>注销</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="微信绑定"
                        name="weixin_bind"
                    >
                        <Select placeholder="请选择微信绑定" allowClear>
                            <Option value={1}>绑定</Option>
                            <Option value={2}>未绑定</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="注册时间"
                        name="createTime"
                    >
                        <RangePicker placeholder="请选择注册时间" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Button style={{float:'right'}} type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                </Col>
            </Row>
            
        </Form>)
    }

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [infoData, setinfoData] = useState({});
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [infoVisible, setInfoVisible] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true);
        api.userlist({page: current, ...parameter}).then(res => {
            setData(res.list)
            setTotal(res.total)
            setloading(false)
        })
    },[current, parameter])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        const create_time = {}
        if (values.create_times) {
            const start = parseInt(moment(moment(values.create_times[0]).format('YYYY-MM-DD')).valueOf()/1000);
            const end = parseInt(values.create_times[0]/1000) === parseInt(values.create_times[1]/1000) ?
            parseInt(moment(moment(values.create_times[1]).add(1, 'days').format('YYYY-MM-DD')).valueOf()/1000) :
            parseInt(moment(moment(values.create_times[1]).format('YYYY-MM-DD')).valueOf()/1000);
            create_time.create_time = `${start}|${end}`
        }
        setParameter(values);
    }

    const openInfo = row => {
        api.userinfo({user_id: row.user_id}).then(res => {
            setinfoData(res);
            setInfoVisible(true);
        })
    }

    const closeInfo = () => {
        setInfoVisible(false);
    }

    const upList = () => {
        api.userlist({page: current, ...parameter}).then(res => {
            console.log(res)
            setData(res.list)
            setTotal(res.total)
            setloading(false)
        })
    }
    return (<>
        <TableBox
            Seach={props.id === undefined ? Seach : null}
            columns={columns}
            total={total}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />
        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeInfo}
            visible={infoVisible}
            width={'65%'}
        >
            <Info data={infoData} upList={upList} />
        </Drawer>
    </>)
}


 
export default User;