import React, {useState, useEffect} from 'react';
import moment from 'moment';
import api from '../../server/api'
import TableBox from '../../container/Table';
import './index.scss';

const InfoLog = props => {
    const columns = [
        {
            title: '账号',
            dataIndex: 'user_name',
            render: text => props.data.user_name,
        },
        {
            title: '创建',
            dataIndex: 'create_time',
            render: text => moment(text*1000).format('YYYY-MM-DD'),
        },
        {
            title: '操作类别',
            dataIndex: 'type',
            render: text => text,
        },
        {
            title: '操作内容',
            dataIndex: 'log',
            render: text => text,
        },
        {
            title: '备注',
            dataIndex: 'reason',
            render: text => text,
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => text === "1" ? '正常' : '注销',
        }
    ]
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(1);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        setLoading(true);
        api.userloglist({user_id: props.data.user_id, page: current}).then(res => {
            setData(res.list);
            setTotal(res.total);
            setLoading(false);
        })
    },[props, current])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }

    return (<>
        <TableBox
            Seach={null}
            columns={columns}
            total={total}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />
    </>)
}


 
export default InfoLog;