import React, {useState, useEffect} from 'react';
import {Select, Form} from 'antd';
import api from '../../../server/api'
import './index.scss';


const ProvinceSelect = props => {
    const [options, setOptions] = useState([]);
    useEffect(() => { // 获取项目来源
        api.proConfig({type: 'project,keyword'}).then(res => {
            const options = [];
            res.project.project_source.forEach((item, index) => {
                options.push({
                    label: item,
                    value: index,
                })
            })
            setOptions(options);
        })
    },[props])
  
    return (
        <Form.Item
            label={props.label}
            name={props.name}
            rules={props.rules}
        >
            <Select
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionFilterProp="children"
                placeholder={props.placeholder}
                options={options} 
            />
        </Form.Item>
        
    )
}

export default ProvinceSelect;