import React, {useState} from 'react';
import {Row, Col, Input} from 'antd';
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';

import './index.scss';



const Abstract = props => {
    const [data, setData] = useState(props.data || []);
    const inputChange = (name, index, value) => {
        const newData = Array.from(data);
        newData[index][name] = value.target.value;
        setData(newData);
        props.onChange(newData);
    }

    const addData = () => {
        const newData = Array.from(data);
        newData.push({
            table_head: "",
            content: ""
        });
        setData(newData);
    }

    const removeData = index => {
        const newData = Array.from(data);
        newData.splice(index, 1);
        setData(newData);
        props.onChange(newData);
    }

    return (<div className='tableRow'>
        <Row align="middle" justify="center">
            <Col span={9} className="absTitle">表头</Col>
            <Col span={2} className="absTitle"></Col>
            <Col span={9} className="absTitle">内容</Col>
            <Col span={2} className="absTitle"></Col>
            <Col span={2} className="absTitle"><PlusOutlined onClick={addData} /></Col>
            {
                data.map((item, index) => (<>
                    <Col span={9} className="absCont"><Input placeholder="请输入表头" value={item.table_head} onInput={inputChange.bind(this, 'table_head', index)} /></Col>
                    <Col span={2} className="absCont"></Col>
                    <Col span={9} className="absCont"><Input placeholder="请输入内容" value={item.content} onInput={inputChange.bind(this, 'content', index)} /></Col>
                    <Col span={2} className="absCont"></Col>
                    <Col span={2} className="absCont"><CloseOutlined onClick={removeData.bind(this, index)} /></Col>
                </>))
            }
        </Row>
    </div>)
}


 
export default Abstract;