import {Component} from 'react';
import {asyncComponent} from 'react-async-component';
import {Route, Switch} from 'react-router-dom';
import {Spin} from 'antd';
import {getIndexDB} from '../server/'

import $Index from '../components/Index';
import $Login from '../components/Login';
import $404 from '../components/404';

import $OrderList from '../components/OrderManagement';
import $UserList from '../components/UserManagement';
import $ContentList from '../components/ContentManagement';
import $TenderList from '../components/TenderManagement';
import $KeywordsList from '../components/KeywordsManagement';
import $MemberList from '../components/MemberManagement';
import $PushList from '../components/PushManagement';
class router extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }

    componentWillMount() {
        const router = [
            // 首页
            { path: '/', component: this.routerAsync($Index) },
            // 登陆页
            { path: '/Login', component: this.routerAsync($Login) },

            // 业务页面
                // 订单
                    // 订单列表
                    { path: '/OrderList', component: this.routerAsync($OrderList) },
                // 用户管理
                    //用户列表
                    { path: '/UserList', component: this.routerAsync($UserList) },
                // 内容管理
                    // 内容列表
                    { path: '/ContentList', component: this.routerAsync($ContentList) },
                // 关键词管理
                    // 关键词列表
                    { path: '/KeywordsList', component: this.routerAsync($KeywordsList) },
                // 招标信息管理
                    // 招标信息列表
                    { path: '/TenderManagementList', component: this.routerAsync($TenderList) },
                // 会员管理
                    // 会员规则
                    { path: '/MemberList', component: this.routerAsync($MemberList) },
                // 推送
                    // 消息推送
                    { path: '/PushList', component: this.routerAsync($PushList) },


            // 404
            { path: '*', component: this.routerAsync($404) }
        ]

        this.setState({
            router
        })
    }

    routerAsync = e => {
        const RouterCommont = {
            ErrorComponent: (state) => this.routerError(state), // 错误文本
            LoadingComponent: () => this.routerLoading(), // 加载中
        }

        return asyncComponent({
            ...RouterCommont,
            resolve: () => this.routerResolve(e)
        })
    }

    routerLoading = () => { // 路由加载中模块
        return <div className='block'><Spin /></div>
    }

    routerResolve = async e => { // 路由拦截及处理
        // token 处理 登陆处理 权限处理
        const token = await getIndexDB('token');
        if (!token) { // 没有登陆信息 跳转到登陆页面
            e = $Login;
        }
        return e;
    }

    routerError = state => { // 路由报错模块
        return <div className='block'>加载错误,请重新再试</div>
    }
    
    
    render() {
        const { router } = this.state;
        return (
            <Switch>
            {
                router.map((item, index) => {
                    return (
                        <Route key={index} exact={true} path={item.path} component={item.component} />
                    )
                })
            }
            </Switch>

        );
    }
}
export default router;
