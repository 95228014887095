import React, {useState, useEffect} from 'react';
import {Select, Form} from 'antd';
import api from '../../server/api'
import './index.scss';


const UserList = props => {
    const [options, setOptions] = useState([]);
    useEffect(() => { // 获会员列表
        api.userlist({page: 1, page_size: 1000000}).then(res => {
            const options = [];
            res.list.forEach(item => {
                options.push({
                    label: `${item.user_name}-${item.cellphone}`,
                    value: item.user_id,
                })
            })
            setOptions(options);
        })
    },[props])
  
    return (
        <Form.Item
            label={props.label}
            name={props.name}
            rules={props.rules}
        >
            <Select
                allowClear
                optionFilterProp="children"
                placeholder={props.placeholder}
                options={options} 
            />
        </Form.Item>
        
    )
}

export default UserList;