import React, {useState, useEffect} from 'react';
import {Select, Form} from 'antd';
import api from '../../../server/api'
import './index.scss';


const ProvinceSelect = props => {
    const [options, setOptions] = useState([]);
    useEffect(() => { // 获取项目列表
        api.projectListp({page: 1, page_size: 100}).then(res => {
            console.log(111, res)
            const options = [];
            res.list.forEach(item => {
                options.push({
                    label: item.project_title,
                    value: item.id,
                    disabled: props.data && props.data.id === item.id
                })
            })
            setOptions(options);
        })
    },[props])
  
    return (
        <Form.Item
            label={props.label}
            name={props.name}
            rules={props.rules}
        >
            <Select
                optionFilterProp="children"
                placeholder={props.placeholder}
                options={options} 
            />
        </Form.Item>
        
    )
}

export default ProvinceSelect;