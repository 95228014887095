import React, {useState} from 'react';
import {Space, Form, Row, Col, Input, Button, message, DatePicker} from 'antd';
import moment from 'moment';
import Abstract from './Abstract'
import api from '../../server/api'

import ProvinceSelect from '../../container/ProvinceSelect'
import RelatedProject from './RelatedProject';
import ProjectSource from './ProjectSource';
import ProjectType from './ProjectType';


import './index.scss';

const {TextArea} = Input;

const Edit = props => {
    const data = props.data;
    // data.publish_time = data.publish_time === "0" ? undefined : data.publish_time;
    data.publish_time = undefined;
    const [provinceId, setprovinceId] = useState(null);
    const onFinish = data => {
        console.log(data);
        const edit = !props.row;
        data.publish_time = parseInt(moment(data.publish_time).valueOf()/1000);
        api.projectCreate({...data, ...edit ? {id: props.data.id} : {}}).then(res => {
            message.success(`${edit ? '修改' : '新增'}成功`)
            props.update();
        })
    }
    const provinceChange = e => { 
        setprovinceId(e)
    }
    return (<>
        <Form
            className='contentEidt'
            colon={false}
            initialValues={data}
            onFinish={onFinish}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="&#12288;&#12288;标题"
                        name="tender_title"
                        rules={[{ required: true, message: '请输入标题' }]}
                    >
                        <Input placeholder='请输入标题' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <RelatedProject
                        data={props.data}
                        label="选择项目"
                        name="related_project"
                        placeholder="请选择选择项目"
                    />
                    <ProjectType
                        data={props.data}
                        label="信息类型"
                        name="tender_type"
                        placeholder="请选择信息类型"
                    />
                    <Form.Item
                        label="项目编号"
                        name="project_no"
                    >
                        <Input placeholder='请输入项目编号' />
                    </Form.Item>
                    <ProjectSource
                        data={props.data}
                        label="项目来源"
                        name="project_source"
                        placeholder="请选择项目来源"
                    />
                    <Form.Item
                        label="发布日期"
                        name="publish_time"
                        rules={[{ required: true, message: '请选择发布日期' }]}
                    >
                        <DatePicker placeholder="请选择发布日期" />
                    </Form.Item>
                    <Form.Item
                        label="&#12288;&#12288;标签"
                        name="tags"
                        rules={[{ required: true, message: '请输入标签' }]}
                    >
                        <Input placeholder='请输入标签' />
                    </Form.Item>
                    <Form.Item
                        label="项目单位"
                        name="project_company"
                        rules={[{ required: true, message: '请输入项目单位' }]}
                    >
                        <Input placeholder='项目单位' />
                    </Form.Item>
                    <ProvinceSelect
                        label="项目省份"
                        name="project_province"
                        rules={[{ required: true, message: '请选择省份' }]}
                        provinceChange={provinceChange}
                        placeholder="请选择项目省份" 
                    />
                    <ProvinceSelect
                        label="项目城市"
                        name="project_city"
                        rules={[{ required: true, message: '请选择城市' }]}
                        type='city'
                        provinceId={provinceId}
                        placeholder="请选择项目城市" 
                    />
                    <Form.Item
                        label="项目金额"
                        name="amount"
                        rules={[{ required: true, message: '请输入金额' }]}
                    >
                        <Input placeholder="请输入项目金额" />
                    </Form.Item>
                    <Form.Item
                        label="&#12288;&#12288;备注"
                        name="remark"
                    >
                        <TextArea placeholder="请输入备注" rows={4} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="&#12288;&#12288;摘要"
                        name="summary"
                        rules={[{ required: true, message: '请添加数据摘要' }]}
                    >
                        <TextArea placeholder="请输入数据摘要" rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="&#12288;&#12288;正文"
                        name="content"
                    >
                        <TextArea placeholder="请输入正文" rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="数据摘要"
                        name="data_summary"
                        rules={[{ required: true, message: '请输入摘要' }]}
                    >
                        <Abstract data={props.data.dataSummary} />
                    </Form.Item>
                    <Form.Item
                        label="原文地址"
                        name="origin_url"
                    >
                        <Input placeholder="请输入原文地址" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Space style={{ float: 'right'}}>
                            <Button onClick={props.closeDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>)
}


 
export default Edit;