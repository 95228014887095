import React from 'react';
import {Divider, Tag, Row, Col, Typography} from 'antd';
import moment from 'moment';
import InfoTable from './index';
const {Paragraph} = Typography;
const Info = props => {
    const base = props.data?.base;
    const dataSummary = props.data?.dataSummary || [];
    const related = [props.data?.base.related_project_info];
    const statistic = props.data?.statistic;
    console.log(props)
    return (<div className="info orderInfo contentInfo">
        <div className='info_title'>
            <label><span><Tag color="#108ee9">项目</Tag></span>{base.tender_title}</label>
            <label><span>创建时间：</span>{moment(base.create_time*1000).format('YYYY-MM-DD')}</label>
        </div>
        <Divider />
        <div className='info_userInfo'>
            <div className='_info'>
                <label><span>ID：</span>{base.id}</label>
                <label><span>项目行业：</span>{base.trade}</label>
                <label><span>项目标签：</span>{base.tags}</label>
                <label><span>项目单位：</span>{base.project_company}</label>
                <label><span>项目省份：</span>{base.project_province}</label>
                <label><span>项目城市：</span>{base.project_city}</label>
                <label><span>项目金额：</span>{base.amount}</label>
                <label><span>备注信息：</span>{base.remark}</label>
            </div>

            <div className='_status'>
                <Row>
                    <Col span={6}>
                        <div className='total'><span>{statistic.month.view}</span>浏览量</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>今日</span>{statistic.today.view}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一周</span>{statistic.week.view}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一月</span>{statistic.month.view}</div>
                    </Col>
                    <Col span={6}>
                        <div className='total'><span>{statistic.month.follow}</span>关注次数</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>今日</span>{statistic.today.follow}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一周</span>{statistic.week.follow}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一月</span>{statistic.month.follow}</div>
                    </Col>
                    <Col span={6}>
                        <div className='total'><span>{statistic.month.share}</span>分享次数</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>今日</span>{statistic.today.share}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一周</span>{statistic.week.share}</div>
                    </Col>
                    <Col span={6}>
                        <div className='statistical'><span>近一月</span>{statistic.month.share}</div>
                    </Col>
                </Row>
            </div>
        </div>
        <Divider />
        <dl className='contentDl'>
            <dt>摘要信息</dt>
            <dd>
                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: '更多' }}>
                    {base.summary}
                </Paragraph>
            </dd>
            <dt>数据摘要</dt>
            <dd>
                <div className='tableRow'>
                    <Row>
                        <Col span={3}>表头</Col>
                        <Col span={3}>内容</Col>
                        <Col span={3}>表头</Col>
                        <Col span={3}>内容</Col>
                        <Col span={3}>表头</Col>
                        <Col span={3}>内容</Col>
                        <Col span={3}>表头</Col>
                        <Col span={3}>内容</Col>
                        {
                            dataSummary.map(item => {
                                return (<>
                                    <Col span={3}>{item.table_head}</Col>
                                    <Col span={3}>{item.content}</Col>
                                </>)
                            })
                        }
                    </Row>
                </div>
            </dd>
            <dt>正文信息</dt>
            <dd>
                <Paragraph ellipsis={{ rows: 10, expandable: true, symbol: '更多' }}>
                    {base.content}
                </Paragraph>
            </dd>
            <dt>关联项目</dt>
            <dd>
                <InfoTable relatedData={related} />
            </dd>
        </dl>
    </div>)
}


 
export default Info;