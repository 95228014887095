import React, {useState} from 'react';
import {withRouter} from "react-router-dom"
import {Form, Input, Button} from 'antd';
import {setIndexDB} from '../../server/';
import api from '../../server/api'

import './index.scss'

const Login = props => {
    const [loading, setLoading] = useState(false);
    const onFinish = data => {
        setLoading(true);
        api.login(data).then(res => {
            console.log(res);
            setIndexDB('token', res.token);
                setIndexDB('userInfo', res.user_info);
                setTimeout(() => {
                    setLoading(false);
                    console.log(props)
                    props.history.push('/OrderList');
                }, 600);
        })
    }
    return (<div className='login'>
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                label="账号"
                name="user_name"
                rules={[{ required: true, message: '请输入账号' }]}
            >
                <Input placeholder="请输入账号" />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
            >
                <Input.Password placeholder="请输入密码" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>)
}

export default withRouter(Login);