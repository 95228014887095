import React from 'react';
import logo from '../../resources/logo.png';
import './index.scss';

const Index = () => {
    return (<div className='index'>
        <img alt="" src={logo} />
    </div>)
}

export default Index;