import React from 'react';
import {Result, Button} from 'antd';

const ErrorPage = props => {
    const goBack = () => {
        props.history.push('/');
    }

    return (<Result status="404" title="404" subTitle="页面未找到"   extra={<Button type="primary" onClick={goBack}>回到首页</Button>} />)
}

export default ErrorPage;