import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col, Input, Modal, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../../server/api';
import TableBox from '../../container/Table';
import Edit from './Edit';
import './index.scss';

const Number = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'level_id',
            render: text => text,
        },
        {
            title: '会员等级',
            dataIndex: 'level_name',
            render: text => text,
        },
        {
            title: '会员价格',
            dataIndex: 'amount',
            render: text => text,
        },
        {
            title: '最近编辑时间',
            dataIndex: 'update_time',
            render: text => moment(text*1000).format('YYYY-MM-DD hh:mm:ss'),
        },
        {
            title: '管理员',
            dataIndex: 'admin_cellphone',
            render: text => text,
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => text === '1' ? '正常' : '禁用',
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            fixed: 'right',
            render: (text, row) => (<>
                <Button type="link" onClick={openEdit.bind(this, row)}>编辑</Button>
                {
                    row.status === '1' ? <Button type="link"><Popconfirm
                        title={`确定要禁用${row.level_name}吗,禁用后将无法使用`}
                        onConfirm={openDisable.bind(this, row, "0")}
                    >禁用</Popconfirm></Button> : <Button type="link"><Popconfirm
                    title={`确定要启用${row.level_name}吗,启用后可继续使用`}
                    onConfirm={openDisable.bind(this, row, "01")}
                >启用</Popconfirm></Button>
                }
            </>),
        },
    ]
    
    const Seach = () => {
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
            <Row>
                <Col span={6}>
                    <Form.Item
                        label="会员信息"
                        name="level_where"
                    >
                        <Input placeholder="请输入ID/名称" />
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item>
                        <Button style={{float: 'left'}} type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                </Col>
            </Row>
            
        </Form>)
    }

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [row, setRow] = useState({});
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [editVisible, setEditVisible] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true);
        api.memberList({page: current, ...parameter}).then(res => {
            setData(res.list);
            setTotal(res.total);
            setloading(false);
        })
    },[current, parameter])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        setParameter(values)
    }

    const openEdit = row => {
        setRow(row);
        setEditVisible(true);
    }

    const openDisable = (row, status) => {
        api.updatelevelconfig({level_id: row.level_id, status}).then(res => {
            console.log(res);
            setloading(true);
            api.memberList({page: current, ...parameter}).then(res => {
                setData(res.list);
                setTotal(res.total);
                setloading(false);
            })
        })
    }

    const closeEdit = () => {
        setloading(true);
        api.memberList({page: current, ...parameter}).then(res => {
            setData(res.list);
            setTotal(res.total);
            setRow({});
            setloading(false);
            setEditVisible(false);
        })
    }

    return (<>
        <TableBox
            Seach={Seach}
            total={total}
            columns={columns}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />

        <Modal
            destroyOnClose
            title="等级编辑"
            footer={null}
            visible={editVisible}
        >
            <Edit row={row} closeEdit={closeEdit} />
        </Modal>
    </>)
}


 
export default Number;