import React from 'react';
import {Form, Button, Row, Col, Input, Space} from 'antd';
import api from '../../server/api';
import './index.scss';

const Edit = ({row, closeEdit}) => {
    const onFinishEdit = values => { // 编辑会员
        api.updatelevelconfig({level_id: row.level_id, ...values}).then(() => {
            closeEdit()
        })
    }
    console.log(row);
    return (<>
        <Form
            colon={false}
            defaultValue={row}
            rules={[{ required: true, message: '请输入账号' }]}
            onFinish={onFinishEdit}
        >
        <Row>
            <Col span={24}>
                <Form.Item
                    label="会员等级"
                    name="level_name"
                    rules={[{ required: true, message: '请输入会员等级' }]}
                >
                    <Input value={row.level_name} placeholder="请输入会员等级" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="会员价格"
                    name="amount"
                >
                    <Input prefix="￥" suffix="RMB" placeholder="请输入会员价格" />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item>
                    <Space style={{float: 'right'}}>
                        <Button type="primary" onClick={closeEdit}>取消</Button>
                        <Button type="primary" htmlType="submit">确定</Button>
                    </Space>
                </Form.Item>
            </Col>
        </Row>
        </Form>
       
    </>)
}


 
export default Edit;