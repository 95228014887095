import React, {useState, useEffect} from 'react';
import {Space, Form, Upload, Row, Col, Input, Button, DatePicker} from 'antd';
import moment from 'moment';
import {UploadOutlined} from '@ant-design/icons';
import UserList from '../../container/UserList';
import api from '../../server/api'
import './index.scss';

const {TextArea} = Input;

const Edit = props => {
    const data = props.data || {};
    const [fileList, setFileList] = useState([])
    const [file, setFile] = useState({})

    useEffect(() => {
        if (props.data?.download_file) {
            setFileList([ {
                uid: '1',
                name: '附件',
                status: 'done',
                url: props.data.download_file,
            },]);
        }
    }, [props])
    const onFinish = values => {
        console.log(values);

        const id = data ? {id: data.id} : {};
        api.messageCreate({...values, ...file, username14: null, push_time: parseInt(moment(moment(values).format('YYYY-MM-DD')).valueOf()/1000), ...id}).then(res => {
            console.log(res);
            props.closeDrawer();
        })
    }

    const customRequest = (data, cb) => {
        const formData = new FormData();
        formData.append('file', data.file, data.file.originName || data.file.name);
        api.upload(formData).then(res => {
            setFileList([ {
                uid: '1',
                name: data.file.originName || data.file.name,
                status: 'done',
                url: res.url,
            },]);
            setFile({download_file: res.url});
        })
        return false;
    }

    return (<>
        <Form
        className='contentEidt'
        colon={false}
        initialValues={data}
        onFinish={onFinish}
    >
        <Row>
            <Col span={24}>
                <UserList
                    label="选择用户"
                    name="user_id"
                    placeholder="请选择用户"
                />
                <Form.Item
                    label="&#12288;&#12288;标题"
                    name="message_title"
                >
                    <Input placeholder='请输入标题' />
                </Form.Item>
                <Form.Item
                    label="&#12288;&#12288;正文"
                    name="message_content"
                >
                    <TextArea placeholder="请输入正文" rows={4} />
                </Form.Item>
                <Form.Item
                    label="&#12288;&#12288;摘要"
                    name="summary"
                >
                <TextArea placeholder="请输入摘要" rows={4} />
                </Form.Item>
                <Form.Item
                    label="上传附件"
                    name="username14"
                >
                    <Upload customRequest={customRequest} fileList={fileList}>
                        <Button icon={<UploadOutlined />}>上传</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="推送时间"
                    name="push_time"
                >
                    <DatePicker placeholder="推送时间" showTime />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item>
                    <Space style={{ float: 'right'}}>
                        <Button onClick={props.closeDrawer}>取消</Button>
                        <Button type="primary" htmlType="submit">提交</Button>
                    </Space>
                </Form.Item>
            </Col>
        </Row>
    </Form>
    </>)
}


 
export default Edit;