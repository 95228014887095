/* eslint-disable import/no-anonymous-default-export */
import axiosApi from './axios'; // axios 接口

const api = {
  // 登录
  login(params = {}) { return axiosApi({method:'post',path:'/admin/passwordlogin',data:params, type:'account'})},
  loginout(params = {}) { return axiosApi({method:'get',path:'/admin/loginout',data:params, type:'account'})}, // 退出登录

  //用户列表
    userlist(params = {}) { return axiosApi({method:'get',path:'/admin/userlist',data:params, type:'account'})}, // 列表
    userinfo(params = {}) { return axiosApi({method:'get',path:'/admin/userinfo',data:params, type:'account'})}, // 详情
    adminuserinfo(params = {}) { return axiosApi({method:'get',path:'/admin/adminuserinfo',data:params, type:'account'})}, // 获取登录用户详情
    userloglist(params = {}) { return axiosApi({method:'get',path:'/admin/userloglist',data:params, type:'account'})}, // 详情
    updatelevel(params = {}) { return axiosApi({method:'post',path:'/admin/updatelevel',data:params, type:'account'})}, // 更新用户等级
    updateleveltime(params = {}) { return axiosApi({method:'post',path:'/admin/updateleveltime',data:params, type:'account'})}, // 更新用户会员到期时间
    userstatus(params = {}) { return axiosApi({method:'post',path:'/admin/userstatus',data:params, type:'account'})}, // 注销用户

  //会员列表
    memberList(params = {}) { return axiosApi({method:'get',path:'/admin/levellist',data:params, type:'account'})}, // 列表
    updatelevelconfig(params = {}) { return axiosApi({method:'post',path:'/admin/updatelevelconfig',data:params, type:'account'})}, // 编辑
    
  // 内容管理
    projectList(params = {}) { return axiosApi({method:'get',path:'/project/list',data:params, type:'tender'})}, // 列表
    projectListp(params = {}) { return axiosApi({method:'get',path:'/project/listp',data:params, type:'tender'})}, // 列表
    projectDetail(params = {}) { return axiosApi({method:'get',path:'/project/detail',data:params, type:'tender'})}, // 新增
    projectDetailp(params = {}) { return axiosApi({method:'get',path:'/project/detailp',data:params, type:'tender'})}, // 新增
    projectCreate(params = {}) { return axiosApi({method:'post',path:'/project/create',data:params, type:'tender'})}, // 新增
    projectSave(params = {}) { return axiosApi({method:'post',path:'/project/save',data:params, type:'tender'})}, // 新增
    projectOffline(params = {}) { return axiosApi({method:'get',path:'/project/offline',data:params, type:'tender'})}, // 上下线

  // 关键词
    keywordList(params = {}) { return axiosApi({method:'get',path:'/keyword/list',data:params, type:'tender'})}, //列表
    keywordCreate(params = {}) { return axiosApi({method:'get',path:'/keyword/create',data:params, type:'tender'})}, // 新增
    keywordOffline(params = {}) { return axiosApi({method:'post',path:'/keyword/offline',data:params, type:'tender'})}, // 关键词下线

  // 订单管理
    orderList(params = {}) { return axiosApi({method:'get',path:'/admin/orderlist',data:params, type:'tender'})}, //列表
    orderinfo(params = {}) { return axiosApi({method:'get',path:'/admin/orderinfo',data:params, type:'tender'})}, //详情
    orderloglist(params = {}) { return axiosApi({method:'get',path:'/admin/orderloglist',data:params, type:'tender'})}, //操作记录
    orderupdateprice(params = {}) { return axiosApi({method:'post',path:'/admin/orderupdateprice',data:params, type:'tender'})}, //调整价格
    ordercancel(params = {}) { return axiosApi({method:'post',path:'/admin/ordercancel',data:params, type:'tender'})}, //取消订单
    orderConfirm(params = {}) { return axiosApi({method:'post',path:'/admin/orderconfirm',data:params, type:'tender'})}, //确认订单凭证

  // 推送管理
    messageList(params = {}) { return axiosApi({method:'get',path:'/message/list',data:params, type:'tender'})}, //列表
    messageCreate(params = {}) { return axiosApi({method:'post',path:'/message/create',data:params, type:'tender'})}, //详情
    messageDeatil(params = {}) { return axiosApi({method:'get',path:'/message/detail',data:params, type:'tender'})}, //创建
    messageOffline(params = {}) { return axiosApi({method:'post',path:'/message/offline',data:params, type:'tender'})}, //上线线

  // 其他
    getcitycode(params = {}) { return axiosApi({method:'get',path:'/project/getcitycode',data:params, type:'tender'})}, // 获取省列表
    upload(params = {}) { return axiosApi({method:'post',path:'/util/upload',data:params, type:'tender', file: true})}, // 上传
    proConfig(params = {}) { return axiosApi({method:'get',path:'/project/config',data:params, type:'tender'})}, // 项目配置 
}

export default api;