import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import {Menu} from 'antd';
import {setIndexDB, getIndexDB} from '../../server/';
import './index.scss';

const {SubMenu} = Menu;
const menuConfig = [{
    name: '订单管理',
    key: 'Order',
    children: [{
        name: '订单列表',
        key: 'OrderList',
    }]
},{
    name: '用户管理',
    key: 'User',
    children: [{
        name: '用户列表',
        key: 'UserList',
    }]
},{
    name: '关键词管理',
    key: 'Keywords',
    children: [{
        name: '关键词列表',
        key: 'KeywordsList',
    }]
},{
    name: '内容管理',
    key: 'Content',
    children: [{
        name: '内容列表',
        key: 'ContentList',
    }]
},{
    name: '招标信息管理',
    key: 'TenderManagement',
    children: [{
        name: '招标信息列表',
        key: 'TenderManagementList',
    }]
},{
    name: '会员管理',
    key: 'Member',
    children: [{
        name: '会员列表',
        key: 'MemberList',
    }]
},{
    name: '推送管理',
    key: 'Push',
    children: [{
        name: '消息列表',
        key: 'PushList',
    }]
},]

const MenuList = () => {
    const [openKeys, setOpenKeys] = useState([])
    const rootSubmenuKeys = [];
    menuConfig.forEach(item => rootSubmenuKeys.push(item.key))

    useEffect(() => { // 获取记录中的menuKey
        getIndexDB('menu').then(res => {
            setOpenKeys(res)
        })
    },[])

    // const onOpenChange = keys => {
    //     const latestOpenKey = keys.find(key => openKeys?.indexOf(key) === -1);
    //     if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
    //       setOpenKeys(keys || []);
    //       setIndexDB('menu', keys);
    //     } else {
    //       setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //       setIndexDB('menu', latestOpenKey ? [latestOpenKey] : []);
    //     }
    // };
    return (
        <>
            <div className="logo">智投必得</div>
            <Menu
                mode="inline"
                theme="dark"
                // openKeys={openKeys}
                // onOpenChange={onOpenChange}
            >
            {
                menuConfig.map((item, index) => <SubMenu key={item.key} title={`${item.name}`}>
                    {
                        item.children.map((items, indexs) => <Menu.Item key={items.key}><Link to={`/${items.key}`}>{items.name}</Link></Menu.Item>)
                    }
                    </SubMenu>
                )
            }
            </Menu>
        </>
    )
}

export default MenuList;