import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col, Input, Select, Drawer, DatePicker, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../../server/api'
import TableBox from '../../container/Table';
import Info from './Info';
import Edit from './Edit';
import ProjectSource from './ProjectSource';
import './index.scss';

const {RangePicker} = DatePicker;
const {Option} = Select;

const Content = props => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '名称',
            dataIndex: 'tender_title',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '项目金额',
            dataIndex: 'amount',
            render: text => text,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: text => moment(text*1000).format('YYYY-MM-DD'),
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => text === "1" ? '正常' : (text === "2" ? '隐藏' : '删除'),
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            width: 220,
            fixed: 'right',
            render: (text, row) => (<>
                {!props.relatedData && <Button type="link" onClick={openEdit.bind(this,row)}>编辑</Button>}
                <Button type="link" onClick={openInfo.bind(this,row)}>详情</Button>
                {!props.relatedData && row.status === '1' && <Button type="link">
                    <Popconfirm
                        title={`确定要禁用${row.project_title}吗,禁用后将无法使用`}
                        onConfirm={openEOffline.bind(this, row)}
                    >
                    下线
                    </Popconfirm>
                </Button>}
                
            </>),
        },
    ]
    
    const Seach = () => {
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
        <Row>
            <Col span={5}>
                <Form.Item
                    label="&#12288;&#12288;名称"
                    name="title"
                >
                    <Input placeholder="请输入名称" />
                </Form.Item>
            </Col>
            <Col span={5}>
                <ProjectSource
                    label="项目来源"
                    name="project_source"
                    placeholder="请选择项目来源"
                />
            </Col>
            <Col span={5}>
                <Form.Item
                    label="&#12288;&#12288;状态"
                    name="status"
                >
                    <Select placeholder="请选择状态" allowClear>
                        <Option value={1}>正常</Option>
                        <Option value={2}>隐藏</Option>
                        <Option value={3}>删除</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item
                    label="创建时间"
                    name="create_time"
                >
                    <RangePicker placeholder="请选择创建时间" />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <Button type="primary" style={{float: 'right'}} onClick={openAdd}>新建</Button>
                </Form.Item>
            </Col>
        </Row>
        </Form>)
    }

    const [data, setData] = useState(props.relatedData || []);
    const [total, setTotal] = useState(0);
    const [infoData, setinfoData] = useState({});
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [infoVisible, setInfoVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        !props.relatedData && setloading(true);
        !props.relatedData && api.projectList({page: current, ...parameter}).then(res => {
            setTotal(res.total);
            setData(res.list);
            setloading(false)
        })
    },[current, parameter, props])

    const openEOffline = row => {
        api.projectOffline({id: row.id}).then(res => {
            !props.relatedData && api.projectList({page: current, ...parameter}).then(res => {
                setTotal(res.total);
                setData(res.list);
                setloading(false)
            })
        })
    }

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        setParameter(values)
    }

    const openInfo = row => {
        const base = api.projectDetail({id: Number(row.id), type: 'base'})
        const statistic = api.projectDetail({id: Number(row.id), type: 'statistic'})
        const dataSummary = api.projectDetail({id: Number(row.id), type: 'dataSummary'})
        Promise.all([base, statistic, dataSummary]).then(res => {
            const dataSummary = [];
            res[2].dataSummary?.forEach(item => {
                dataSummary.push({
                    table_head: item.table_head,
                    content: item.content,
                })
            })
            console.log(res)
            const data = {
                base: res[0].base,
                statistic: res[1].statistic,
                dataSummary,
            }
            setinfoData(data);
            setInfoVisible(true);
        });
    }

    const openAdd = row => {
        setinfoData({})
        setEditVisible(true)
    }
    const openEdit = row => {
        const base = api.projectDetail({id: Number(row.id), type: 'base'})
        const dataSummary = api.projectDetail({id: Number(row.id), type: 'dataSummary'})
        Promise.all([base, dataSummary]).then(res => {
            const dataSummary = [];
            res[1].dataSummary?.forEach(item => {
                dataSummary.push({
                    table_head: item.table_head,
                    content: item.content,
                })
            })
            const data = {
                ...res[0].base,
                type: Number(res[0].base.type),
                dataSummary,
            }
            setinfoData(data);
            setEditVisible(true);
        });
    }

    const closeInfo = () => {
        setInfoVisible(false);
    }

    const closeEdit = () => {
        setEditVisible(false);
    }

    const update = () => {
        setloading(true);
        api.projectList({page: current, ...parameter}).then(res => {
            setTotal(res.total);
            setData(res.list);
            setloading(false);
            setEditVisible(false);
        })
    }

    return (<>
        <TableBox
            Seach={props.relatedData === undefined ? Seach : null}
            total={total}
            columns={columns}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />
        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeInfo}
            visible={infoVisible}
            width={'65%'}
        >
            <Info data={infoData} />
        </Drawer>

        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeEdit}
            visible={editVisible}
            width={'65%'}
        >
            <Edit data={infoData} closeDrawer={closeEdit} update={update} />
        </Drawer>
    </>)
}


 
export default Content;