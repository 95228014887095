import React from 'react';
import { Layout } from 'antd';

import Head from './components/Header'
import Menu from './components/Menu'
import {getIndexDB} from './server';
import Router from './router'

const userInfo = JSON.parse(JSON.stringify(getIndexDB('userInfo')));
const { Header, Sider, Content } = Layout;

export default class App extends React.Component {
  
  render() {
    return (
      <Layout>
        {
          Object.keys(userInfo).length === 0 ? <>
            <Sider><Menu /></Sider>
            <Layout>
              <Header><Head userInfo={userInfo} /></Header>
              <Content><Router /></Content>
            </Layout>
          </> : <>
            <Content><Router /></Content>
          </>
        }
      </Layout>
    );
  }
}