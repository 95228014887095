
import {getIndexDB, setIndexDB} from './index';
import {message} from 'antd';
import axios from 'axios';
// import qs from 'qs';
import apiRoot from './root'

const axiosApi = async item => {
    let res = {}
    const token = await getIndexDB('token');
    if(!token && item.path !== '/admin/passwordlogin') { // 没有用户信息，重新登陆
        return Promise.reject({
            msg : '用户没有登陆'
        })
    } 
    const data = {...item.data}
    for(const i in data) {
        if(data[i] === undefined || data[i] === null) {
            delete data[i]
        }
    }
    if(item.method === 'get') {
        item.path += '?';
        Object.keys(data)?.forEach(items =>{
           (!!data[items] || data[items] === 0) && (item.path += items + '=' + data[items] + '&')
        })
        
    }

    item.path += `${item.method === 'get' ? '' : '?'}${data.token ? '' : `token=${token}`}`
    
    const axiosDown = resData => {
        if (resData.error_no === 0) {
            return resData.data;
        } else if (resData.error_no === 10001) {
            setIndexDB('token', null);
            setIndexDB('userInfo', null);
            // window.location.href = '/login';
        } else {
            message.error(resData.error_message);
            return Promise.reject({
                msg: resData.error_message
            })
        }
    }
    
    const dataJson = {
        method : item.method,
        url: apiRoot[item.type] + item.path,
        // data: qs.stringify(data),
    }
    if (item.file) {
        dataJson.headers = {"Content-Type":"multipart/form-data"};
        dataJson.data = item.data
    } else {
        dataJson.data = JSON.stringify(data);
        dataJson.responseType = 'json';
    }

    res = await axios(dataJson);
    return axiosDown(res.data)
        
    // if(request.code === 1) { // 请求失败，操作异常
    //     return false;
    // } else if(request.code === 400) { // 请求失败，参数异常
    //     return false;
    // } else if(request.code === 401) { // 请求失败，未授权
    //     return false;
    // } else if(request.code === 403) { // 请求失败，拒绝访问
    //     return false;
    // } else if(request.code === 500) { // 请求失败，服务器异常
    //     return false;
    // }
    
}

export default axiosApi;