import React from 'react';
import moment from 'moment'
const Info = props => {
    console.log(props)
    const {data} = props;
    return (<div className="info orderInfo contentInfo">
        <div className='info_userInfo'>
            <div className='_info' style={{border: 'none'}}>
                <label><span>目标用户：</span>{data.user_id}-{data.phone}</label>
                <label><span>标题：</span>{data.message_content}</label>
                <label><span>摘要：</span>{data.summary}</label>
                <label><span>正文：</span>{data.message_content}</label>
                <label><span>推送时间：</span>{moment(data.update_time*1000).format('YYYY-MM-DD')}</label>
                <label><span>附件：</span>{data.download_file && <img alt="" src={data.download_file} />}</label>
            </div>
        </div>            
    </div>)
}


 
export default Info;