import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col, Input, Select, Drawer, DatePicker, Popconfirm} from 'antd';
import moment from 'moment';
import api from '../../server/api'
import TableBox from '../../container/Table';
import Info from './Info';
import Edit from './Edit';
import './index.scss';

const {Option} = Select;
const {RangePicker} = DatePicker;

const Content = props => {
    const columns = [
        {
            title: '标题',
            dataIndex: 'message_title',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '用户ID',
            dataIndex: 'user_id',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '用户手机号',
            dataIndex: 'phone',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '状态',
            dataIndex: 'is_push',
            render: text => text === '0' ? '未推送' : '已推送',
        },
        {
            title: '上下线',
            dataIndex: 'is_online',
            render: text => text === '0' ? '下线' : '上线',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: text => moment(text*1000).format('YYYY-MM-DD'),
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            width: 220,
            fixed: 'right',
            render: (text, row) => (<>
                <Button type="link" onClick={openEdit.bind(this, row)}>编辑</Button>
                <Button type="link" onClick={openInfo.bind(this, row)}>详情</Button>
                {
                    row.is_online === "0" ? <Button type="link"><Popconfirm
                        title="上线后恢复显示"
                        onConfirm={confirm.bind(this, row, 1)}
                    >上线</Popconfirm></Button> :
                    <Button type="link"><Popconfirm
                    title="下线后，改推送对用户不可见"
                    onConfirm={confirm.bind(this, row, 0)}
                >下线</Popconfirm></Button>
                }
                
            </>),
        },
    ]
    const Seach = () => {
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
            <Row>
                <Col span={5}>
                    <Form.Item
                        label="用户信息"
                        name="username"
                    >
                        <Input placeholder="请输入ID/手机号" />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        label="推送状态"
                        name="is_push"
                    >
                        <Select placeholder="请选择状态">
                            <Option value={'0'}>未推送</Option>
                            <Option value={'1'}>已推送</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        label="上下线"
                        name="is_online"
                    >
                        <Select placeholder="请选择上下线">
                            <Option value={'0'}>下线</Option>
                            <Option value={'1'}>上线</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        label="创建时间"
                        name="create_time"
                    >
                        <RangePicker placeholder="请选择创建时间" />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                        <Button style={{float: 'right'}} type="primary" onClick={addPush}>新增</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>)
    }

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [infoData, setinfoData] = useState({});
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [infoVisible, setInfoVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [loading, setloading] = useState(false);

    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true)
        api.messageList({page: current, ...parameter}).then(res => {
            setData(res.messages || [])
            setloading(false)
        })
    },[current, parameter])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        setParameter(values)
    }

    const addPush = () => {
        setinfoData({});
        setEditVisible(true);
    }

    const openInfo = row => {
        api.messageDeatil({id: row.id}).then(res => {
            res.push_time = moment(res.push_time*1000);
            setinfoData(res);
            setInfoVisible(true);
        })
        
    }

    const openEdit = row => {
        api.messageDeatil({id: row.id}).then(res => {
            res.push_time = moment(res.push_time*1000);
            setinfoData(res);
            setEditVisible(true);
        })
    }

    const closeInfo = () => {
        setInfoVisible(false);
    }

    const confirm = (row, status) => {
        api.messageOffline({id: Number(row.id) , is_online: status}).then(res => {
            api.messageList({page: current, ...parameter}).then(res => {
                setData(res.messages || [])
                setloading(false)
            })
        })
    }

    const closeEdit = () => {
        setEditVisible(false);
        api.messageList({page: current, ...parameter}).then(res => {
            setData(res.messages || []);
            setTotal(res.count);
            setloading(false);
        })
    }

    return (<>
        <TableBox
            Seach={Seach}
            total={total}
            columns={columns}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />
        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeInfo}
            visible={infoVisible}
            width={'65%'}
        >
            <Info data={infoData} />
        </Drawer>

        <Drawer
            destroyOnClose
            closable={false}
            onClose={closeEdit}
            visible={editVisible}
            width={'65%'}
        >
            <Edit data={infoData} closeDrawer={closeEdit} />
        </Drawer>
    </>)
}


 
export default Content;