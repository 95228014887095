import React from 'react';
import {Space, Form, Row, Col, Input, Button, message} from 'antd';
import moment from 'moment';
import api from '../../server/api'

import ProjectSource from './ProjectSource';


import './index.scss';
const Edit = props => {
    const data = props.data;
    // data.publish_time = data.publish_time === "0" ? undefined : data.publish_time;
    data.publish_time = undefined;
    const onFinish = data => {
        console.log(data);
        const edit = !props.row;
        data.publish_time = parseInt(moment(data.publish_time).valueOf()/1000);
        api.projectSave({...data, ...edit ? {id: props.data.id} : {}}).then(res => {
            message.success(`${edit ? '修改' : '新增'}成功`)
            props.update();
        })
    }
    return (<>
        <Form
            className='contentEidt'
            colon={false}
            initialValues={data}
            onFinish={onFinish}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="项目名称"
                        name="project_title"
                    >
                        <Input placeholder='请输入项目名称' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="项目编号"
                        name="project_no"
                    >
                        <Input placeholder='请输入项目编号' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <ProjectSource
                        data={props.data}
                        label="项目来源"
                        name="project_source"
                        placeholder="请选择项目来源"
                    />
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Space style={{ float: 'right'}}>
                            <Button onClick={props.closeDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>)
}


 
export default Edit;