import React, {useState, useEffect} from 'react';
import {Select, Form} from 'antd';
import api from '../../server/api'
import './index.scss';


const ProvinceSelect = props => {
    const [options, setOptions] = useState([]);

    useEffect(() => { // 获取省级列表
        const data = props.type ? {province: props.provinceId} : {};
        (props.type !== 'city' || props.provinceId) && api.getcitycode(data).then(res => {
            const list = [];
            Object.keys(res).forEach(item => {
                list.push({
                    value: Number(item),
                    label: res[item],
                })
            })
            setOptions(list)
        })
    },[props])
  
    return (
        <Form.Item
            label={props.label}
            name={props.name}
            rules={props.rules}
        >
            <Select onChange={props.provinceChange} placeholder={props.placeholder} options={options} />
        </Form.Item>
        
    )
}

export default ProvinceSelect;