// process.env: development 线环境
// process.env: production 线上环境
const apiDevRoot = {
    tender: 'http://master.zt-tender.zt-dev.zhitoubide.com',
    account: 'http://master.zt-account.zt-dev.zhitoubide.com'
}

const apiProRoot = {
    tender: 'https://tender.zhitoubide.com',
    account: 'https://account.zhitoubide.com'
}

const apiRoot = process.env.NODE_ENV === 'development' ? apiDevRoot : apiProRoot;
export default apiRoot;
