// key key名

import * as localforage from 'localforage' // 使用localforage库，当浏览器不支持本地数据库的时候，启用本地存储

export const setIndexDB = (key, value) => {
    if(value === null ) { // 删除
        localforage.removeItem(key)
    } else {
        localforage.setItem(key,value).then(res => {
            // 保存成功
        }).catch(error => {
            console.log(error)
        })
    }
}

export const getIndexDB = key => {
    return localforage.getItem(key);
}