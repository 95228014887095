import React, {useState, useEffect} from 'react';
import {Tabs, Divider, Steps, Button, Space, Modal, Form, Row, Col, Input, Radio, message} from 'antd';
import moment from 'moment';
import api from '../../server/api'
import TableBox from '../../container/Table';

const {TabPane} = Tabs;
const {TextArea} = Input;

const { Step } = Steps;
const columns = [
    {
        title: '账号',
        dataIndex: 'user_id',
        render: text => text,
    },
    {
        title: '时间',
        dataIndex: 'create_time',
        render: text => moment(text*1000).format('YYYY-MM-DD hh:mm:Ss'),
    },
    {
        title: '操作类别',
        dataIndex: 'type',
        render: text => text,
    },
    {
        title: '操作内容',
        dataIndex: 'log',
        render: text => text,
    },
    {
        title: '备注',
        dataIndex: 'reason',
        render: text => text,
    }
]

const Order = props => {
    const [data, setData] = useState(props.data || {});
    const [list, setList] = useState([]);
    const [activeKey, setActiveKey] = useState("1");
    const [current, setCurrent] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setloading] = useState(false);
    const [pricingVisible, setPricingVisible] = useState(false);
    const [closeOrderVisible, setCloseOrderVisible] = useState(false);
    const [OpenMemberVisible, setOpenMemberVisible] = useState(false);
    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true)
        api.orderloglist({page:current, order_no: data.order_no}).then(res => {
            setList(res.list);
            setTotal(res.total);
            setloading(false);
        })
    },[current, data])

    const callback = e => {
        setActiveKey(e);
    }

    const onChange = e => {
        setCurrent(e)
    }

    const openPricing = () => {
        setPricingVisible(true);
    }

    const openCloseOrder = () => {
        setCloseOrderVisible(true);
    }

    const openMember = values => {
        setOpenMemberVisible(true);
    }

    const closeDrawer = e => {
        console.log(111);
        setPricingVisible(false);
        setCloseOrderVisible(false);
        setOpenMemberVisible(false);
    }

    const onFinishPricing = values => {
        api.orderupdateprice({order_no: data.order_no, ...values}).then(() => {
            data.amount = values.amount;
            setData(data);
            message.success('调整成功')
            setPricingVisible(false);
        })
    }

    const onFinishOpenMember = values => {
        values.reason === 1 ? api.orderConfirm({order_no: data.order_no}).then(res => {
            data.status = '40';
            setData(data);
            message.success('开通成功')
            props.upList && props.upList();
            setOpenMemberVisible(false)
        }) : setOpenMemberVisible(false)
    }

    const onFinishCloseOrder = values => {
        api.ordercancel({...values, order_no: data.order_no}).then(res => {
            message.success('订单已关闭');
            data.status = "100";
            data.system_cancel_time = moment(new Date());
            setData(data);
            setCloseOrderVisible(false);
            props.upList()
        })
    }

    const okConfirm = () => {
        api.orderConfirm({order_no: data.order_no}).then(res => {
            data.status = '40';
            setData(data);
            message.success('开通成功')
            props.upList && props.upList();
            setOpenMemberVisible(false)
        })
    }
    
    return (<div className="info orderInfo">
        <Tabs activeKey={activeKey} onChange={callback}>
            <TabPane tab="订单详情" key="1">
                <div className='info_title'>
                    <label><span>订单号：</span>{data.order_no}</label>
                    <label><span>订单来源：</span>{data.source === "1" ? '网站' : '小程序'}</label>
                </div>
                <Divider />
                <div className='info_userInfo'>
                    <div className='_info'>
                        <div className='_info_status'>
                        {
                            data.status === "10" ? '待付款' : // 线上未付款
                            data.status === "20" ? '待审核' :  // 线下打款
                            data.status === "40" ? '完成' : '订单取消'
                        }
                    </div>
                        <label><span>会员等级：</span>{data.level_name}</label>
                        <label><span>用户ID：</span>{data.user_id}</label>
                        <label><span>用户手机号：</span>{data.cellphone}</label>
                        <label><span>购买时等级：</span>{data.user_level_name}</label>
                    </div>

                    <div className='_status'>
                        <Steps current={1} status={data.status === "40" ? "finish" : data.status === "100" ? "error" : "process"}>
                            <Step title="用户提交订单" description={moment(data.create_time*1000).format('YYYY-MM-DD hh:mm:ss')} />
                            {
                                data.status === "100" && <Step title="订单取消" 
                                    description={moment(data.update_time*1000).format('YYYY-MM-DD hh:mm:ss')} 
                                    subTitle={data.update_time ? '后台取消' : '用户取消'}
                                />
                            }
                            {
                                data.status === "40" && <Step title="订单完成" 
                                    description={moment(data.update_time*1000).format('YYYY-MM-DD hh:mm:ss')} 
                                />
                            }
                        </Steps>
                        {
                            (data.status === "10" ||  data.status === "20") && <div className='status_button'>
                                <Space className='_status_button'>
                                    <Button type='primary' onClick={openCloseOrder}>关闭订单</Button>
                                    <Button type='primary' onClick={openPricing}>调价</Button>
                                </Space>
                            </div>
                        }
                    </div>
                </div>
                {
                    (data.status === "40" || data.status === "20") &&<>
                        <Divider />
                        <div className='info_userInfo _orderInfo'>
                            <div className='_info'>
                                <label><span>支付时间：</span>{moment(data.receipt_time*1000).format('YYYY-MM-DD hh:mm:ss')}</label>
                                <label><span>支付方式：</span>{
                                    data.status === "10" ? '未付款' : '线下打款'// 线上未付款
                                }</label>
                                <label><span>第三方流水号：</span>{data.receipt_no}</label>
                                <label><span>打款证明：</span><img alt='打款证明' src={data.receipt_url} /></label>
                                <br />
                                {(data.status === "20") && <label><span></span><Button type='primary' onClick={openMember}>确认订单凭证</Button></label>}
                            </div>
        
                            <div className='_status'>
                                <div className='labelInline'>
                                    <label ><span>会员价格：</span>￥{data.level_amount}元</label>
                                    <label ><span>优惠价格：</span>￥{data.coupon_amount}元</label>
                                </div>
                                <div className='status_button'>
                                    <Space className='_status_button'>
                                        <div className='_order_conclusion'>
                                            <label ><span>总计：</span></label>
                                            <label ><span>订单金额：</span>￥{data.level_amount}元</label>
                                            <label ><span>优惠金额：</span>￥{data.coupon_amount}元</label>
                                            <label ><span>实付金额：</span>￥{data.amount}元</label>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </TabPane>
            <TabPane tab="操作记录" key="2">
            <TableBox
                columns={columns}
                total={total}
                data={list}
                current={current}
                onChange={onChange}
                loading={loading} 
            />
            </TabPane>
        </Tabs>

        <Modal
            title="调价"
            destroyOnClose
            footer={null}
            closable={false}
            onClose={closeDrawer}
            visible={pricingVisible}
            width={'60%'}
        >
            <Form
                colon={false}
                onFinish={onFinishPricing}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="当前实付金额"
                        >
                            {data.amount}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="调后实付金额"
                            name="amount"
                            rules={[{ required: true, message: '请填写调后实付金额' }]}
                        >
                            <Input placeholder="请填写调后实付金额" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="&#12288;&#12288;调整原因"
                            name="reason"
                            rules={[{ required: true, message: '请填写调整原因' }]}
                        >
                            <TextArea placeholder="请填写调整原因" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space style={{float: 'right'}}>
                            <Button onClick={closeDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal
            destroyOnClose
            title="关闭订单"
            closable={false}
            footer={null}
            onClose={closeDrawer}
            visible={closeOrderVisible}
            width={'60%'}
        >
            <Form
                colon={false}
                onFinish={onFinishCloseOrder}
            >
                <Row>
                    <Col span={24}>
                        是否确认关闭该订单，确认请填写调整原因后提交<br /><br />
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="调整原因"
                            name="reason"
                            rules={[{ required: true, message: '请填写调整原因' }]}
                        >
                            <TextArea placeholder="请填写调整原因" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space style={{float: 'right'}}>
                            <Button onClick={closeDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal
            title="开通会员"
            destroyOnClose
            closable={false}
            footer={null}
            onClose={closeDrawer}
            visible={false}
            width={'60%'}
        >
            <Form
                colon={false}
                onFinish={onFinishOpenMember}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="是否通过"
                            name="reason"
                            rules={[{ required: true, message: '请填写调整原因' }]}
                        >
                        <Radio.Group>
                            <Radio value={1}>通过</Radio>
                            <Radio value={2}>不通过</Radio>
                        </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="调整原因"
                            name="content"
                            rules={[{ required: true, message: '请填写调整原因' }]}
                        >
                            <TextArea placeholder="请填写调整原因" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space style={{float: 'right'}}>
                            <Button onClick={closeDrawer}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal
            title="确认订单凭证，开通会员"
            destroyOnClose
            closable={false}
            onCancel={closeDrawer}
            onOk={okConfirm}
            visible={OpenMemberVisible}
            width={'60%'}
        >
            确认后该用户会开通{data.level_name}，确认吗？
        </Modal>
    </div>)
}


 
export default Order;