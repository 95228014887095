import React from 'react';
import {Table} from 'antd';
import './index.scss';

const TableBox = ({Seach= null, data= [], columns= [], current= 1, total= 0, pagination= {}, onChange, onShowSizeChange, loading}) => {
    const paginationOpts = {
        current,
        total,
        hideOnSinglePage: true,
        pageSize: 20,
        pageSizeOptions: [20, 40, 60, 80],
        showQuickJumper: true,
        onChange: onChange,
        onShowSizeChange: onShowSizeChange,
        ...pagination
    }
    return (<div className='tableBox'>
        {
            Seach && <div className='Seach'>
                <Seach />   
            </div>
        }
        <div className='Table'>
            <Table
                tableLayout="fixed"
                rowKey="id"
                columns={columns}
                loading={loading}
                dataSource={data}
                pagination={paginationOpts}
            />
        </div>
    </div>)
}

export default TableBox;