import React, {useState} from 'react';
import {Tabs, Form, Row, Col, Button, Radio, DatePicker, Space, Modal, Input, message} from 'antd';
import moment from 'moment';
import OrderList from '../OrderManagement'
import UserManagement from '../../container/LevelConfig';
import InfoLog from './InfoLog'
import './index.scss';
import { api } from '../../server';

const {TabPane} = Tabs;
const {TextArea} = Input;

const Seach = <div>
    <Row>
        <Col span={6}>
            <Form.Item
                label="下单时间"
                name="username4"
            >
                <DatePicker placeholder="请选择下单时间" />
            </Form.Item>
        </Col>
        <Col span={16}>
            <Form.Item
                label="订单状态"
                name="username7"
            >
                <Radio.Group defaultValue="a" buttonStyle="solid">
                    <Radio.Button value="a">全部订单</Radio.Button>
                    <Radio.Button value="b">待付款-全部</Radio.Button>
                    <Radio.Button value="c">待付款-转账待审核</Radio.Button>
                    <Radio.Button value="d">已完成</Radio.Button>
                    <Radio.Button value="e">已取消</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </Col>
        <Col span={2}>
            <Form.Item>
                <Button style={{float: 'right'}} type="primary" htmlType="submit">搜索</Button>
            </Form.Item>
        </Col>
    </Row>
</div>

const Info = props => {
    const [data, setData] = useState(props.data || {});
    const [editActiveKey, seteditActiveKey] = useState("1");
    const [periodVisible, setPeriodVisible] = useState(false);
    const [userstatusVisible, setUserstatusVisible] = useState(false);
    
    const callbackEdit = e => {
        seteditActiveKey(e);
    }

    const closeEdit = () => {
        setPeriodVisible(false)
    }

    const closeUserStatus = () => {
        setUserstatusVisible(false)
    }

    const openEdit = () => {
        setPeriodVisible(true)
    }

    const openCancel = () => {
        setUserstatusVisible(true)
    }

    const onFinishPeriod = values => {
        api.updatelevel({user_id: data.user_id, ...values}).then(res => {
            message.success('调整成功');
            data.status = values.status;
            setPeriodVisible(false);
            props.upList();
        })
    }

    const onFinishuserTime = values => {
        const level_end_time = moment(values.level_end_time).format('YYYY-MM-DD hh:mm:ss');
        api.updateleveltime({user_id: data.user_id, ...values, level_end_time}).then(res => {
            message.success('调整成功');
            data.level_end_time = moment(values.level_end_time).valueOf()/1000;
            setPeriodVisible(false);
        })
    }

    const onFinishUserStatus = values => {
        api.userstatus({user_id: data.user_id, ...values,}).then(res => {
            message.success('调整成功');
            setData({...data, ...values});
            props.upList();
            setUserstatusVisible(false);
        })
    }
    return (<div className="info usefInfo">
        <div className="basic">
            <Row>
                <Col span={4}>
                    <div className='userPortrait'>
                        <img alt='微信头像' src={data.bind_wechat_qr} />
                        <div>{data.level_start_time !== '0' && moment(data.level_start_time*1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                        <div>{data.level_end_time !== '0' && moment(data.level_end_time*1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                        <div>
                            <Space>
                                <Button type="primary" onClick={openEdit}>调整</Button>
                                <Button type="primary" onClick={openCancel}>账号注销</Button>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <label><span>ID</span>{data.user_id}</label>
                    <label><span>注册时间</span>{moment(data.create_time*1000).format('YYYY-MM-DD hh:mm:ss')}</label>
                    <label><span>所在行业</span>{data.industry}</label>
                    <label><span>绑定微信</span>{!data.weixin_code ? '否' : '是'}</label>
                </Col>
                <Col span={6}>
                    <label><span>注册号码</span>{data.cellphone}</label>
                    <label><span>最后登录时间</span>{moment(data.last_login_time*1000).format('YYYY-MM-DD hh:mm:ss')}</label>
                    <label><span>公司名称</span>{data.company}</label>
                    <label><span>微信号</span>{data.weixin_code}</label>
                </Col>
                <Col span={6}>
                    <label><span>昵称</span>{data.user_name}</label>
                    <label><span>注册渠道</span>{data.source_str}</label>
                    <label><span>电子邮箱</span>{data.email}</label>
                </Col>
            </Row>
        </div>
        <Tabs>
            <TabPane tab="订单记录" key="1">
                <OrderList Seach={Seach} userId={data.user_id} />
            </TabPane>
            <TabPane tab="操作记录" key="2">
                <InfoLog data={data} />
            </TabPane>
        </Tabs>

        <Modal
            title="会员调整"
            destroyOnClose
            footer={null}
            closable={false}
            visible={periodVisible}
            width={'65%'}
        >
            <Tabs activeKey={editActiveKey} onChange={callbackEdit}>
                <TabPane tab="会员等级" key="1">
                    <Form
                        colon={false}
                        onFinish={onFinishPeriod}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="当前等级"
                                >
                                    {data.level_name}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <UserManagement
                                    label="会员等级"
                                    name="level"
                                    rules={[{ required: true, message: '请选择会员等级' }]}
                                    placeholder="请选择会员等级"
                                />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="调整原因"
                                    name="reason"
                                    rules={[{ required: true, message: '请填写调整原因' }]}
                                >
                                    <TextArea placeholder="请填写调整原因" rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Space style={{float: 'right'}}>
                                    <Button onClick={closeEdit}>取消</Button>
                                    <Button type="primary" htmlType="submit">确定</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="有效期" key="2">
                    <Form
                        colon={false}
                        onFinish={onFinishuserTime}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="当前有效期"
                                >
                                    {moment(data.level_end_time*1000).format('YYYY-MM-DD hh:mm:ss')}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="选择有效期"
                                    name="level_end_time"
                                    rules={[{ required: true, message: '请选择有效期' }]}
                                >
                                    <DatePicker placeholder="请选择有效期" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="调整原因"
                                    name="reason"
                                    rules={[{ required: true, message: '请填写调整原因' }]}
                                >
                                    <TextArea placeholder="请填写调整原因" rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Space style={{float: 'right'}}>
                                    <Button onClick={closeEdit}>取消</Button>
                                    <Button type="primary" htmlType="submit">确定</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>
            
        </Modal>

        <Modal
            title="账号注销/恢复"
            destroyOnClose
            footer={null}
            closable={false}
            visible={userstatusVisible}
            width={'65%'}
        >
            <Form
                colon={false}
                onFinish={onFinishUserStatus}
                initialValues={data}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="&#12288;&#12288;状态"
                            name="status"
                            rules={[{ required: true, message: '请填写注销原因' }]}
                        >
                            <Radio.Group>
                                <Radio value={"1"}>正常</Radio>
                                <Radio value={"2"}>注销</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="调整原因"
                            name="cancel_reason"
                            rules={[{ required: true, message: '请填写注销原因' }]}
                        >
                            <TextArea placeholder="请填写注销/恢复原因" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space style={{float: 'right'}}>
                            <Button onClick={closeUserStatus}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>)
}


 
export default Info;