import React, {useState, useEffect} from 'react';
import {Form, Button, Row, Col, Input, Select, DatePicker, Modal, Space, message, Popconfirm} from 'antd';
import moment from 'moment'
import TableBox from '../../container/Table';
import api from '../../server/api'
import './index.scss';

const {Option} = Select;
const {TextArea} = Input;
const {RangePicker} = DatePicker;

const Keywords = ({id}) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '类型',
            dataIndex: 'keyword_type',
            fixed: 'left',
            render: text => text === "1" ? '关键词' : '排除词',
        },
        {
            title: '名称',
            dataIndex: 'keyword',
            fixed: 'left',
            render: text => text,
        },
        {
            title: '使用次数',
            dataIndex: 'used_count',
            render: text => text,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            render: text => moment(text*1000).format('YYYY-MM-DD'),
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: text => text === '1' ? '上线' : '下线',
        },
        {
            title: '操作',
            dataIndex: 'name13',
            align: 'center',
            width: 220,
            fixed: 'right',
            render: (text, row) => (<>
                {
                    row.status === '1' ? <Button type="link"><Popconfirm
                    title={`关键词${row.keyword}下线之后将不可用,确定下线吗`}
                    onConfirm={keywordOffline.bind(this, row, "0")}
                    okText="确定"
                    cancelText="取消"
                >下线</Popconfirm></Button> : <Button type="link"><Popconfirm
                title={`关键词${row.keyword}上线将恢复使用,确定上线吗`}
                onConfirm={keywordOffline.bind(this, row, "1")}
                okText="确定"
                cancelText="取消"
            >上线</Popconfirm></Button>
                }
            </>),
        },
    ]
    
    const Seach = () => {
        return (<Form
            colon={false}
            initialValues={parameter}
            onFinish={onFinish}
        >
            <Row>
                <Col span={6}>
                    <Form.Item
                        label="&#12288;&#12288;名称"
                        name="title"
                    >
                        <Input placeholder="请输入ID/名称" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="&#12288;&#12288;类型"
                        name="type"
                    >
                        <Select placeholder="请选择类型" allowClear>
                            <Option value={"1"}>关键词</Option>
                            <Option value={"2"}>排除词</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="&#12288;&#12288;状态"
                        name="status"
                    >
                        <Select placeholder="请选择状态" allowClear>
                            <Option value={"1"}>上线</Option>
                            <Option value={"0"}>下线</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="列表排序"
                        name="sort"
                    >
                        <Select placeholder="请选择列表排序" allowClear>
                            <Option value={'create_time|DESC'}>按创建时间倒序</Option>
                            <Option value={'create_time|ASC'}>按创建时间正序</Option>
                            <Option value={'used_count|ASC'}>按使用次数正序</Option>
                            <Option value={'used_count|DESC'}>按使用次数倒序</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="创建时间"
                        name="create_times"
                    >
                        <RangePicker placeholder="请选择创建时间" allowClear/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">搜索</Button>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Button style={{float: 'right'}} type="primary" onClick={newKey}>新建</Button>
                    </Form.Item>
                </Col>
            </Row>
            
        </Form>)
    }

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [parameter, setParameter] = useState({});
    const [loading, setloading] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    useEffect(() => { // 分页 或 搜索条件变换时执行
        setloading(true);
        api.keywordList({page: current, ...parameter}).then(res => {
            setData(res.list);
            setTotal(res.count);
            setloading(false)
        })
    },[current, parameter])

    const onChange = (e) => { //切换分页
        setCurrent(e)
    }
    
    const onFinish = values => { // 修改搜索条件
        const create_time = {}
        if (values.create_times) {
            const start = parseInt(moment(moment(values.create_times[0]).format('YYYY-MM-DD')).valueOf()/1000);
            const end = parseInt(values.create_times[0]/1000) === parseInt(values.create_times[1]/1000) ?
            parseInt(moment(moment(values.create_times[1]).add(1, 'days').format('YYYY-MM-DD')).valueOf()/1000) :
            parseInt(moment(moment(values.create_times[1]).format('YYYY-MM-DD')).valueOf()/1000);
            create_time.create_time = `${start}|${end}`
        }
        const serData = {...values, ...create_time}
        // delete serData.create_times;
        setParameter(serData);
    }

    const onFinishEdit = values => { // 修改搜索条件
        api.keywordCreate(values).then(res => {
            message.success('添加成功');
            setEditVisible(false);
            api.keywordList({page: current, ...parameter}).then(res => {
                setData(res.list);
                setTotal(res.count);
                setloading(false)
            })
        })
    }

    const newKey = () => {
        setEditVisible(true);
    }

    const closeEdit = () => {
        Modal.destroyAll();
        setEditVisible(false);
    }

    const keywordOffline = (row, status) => {
        api.keywordOffline({id: row.id, status}).then(() => {
            setloading(true)
            message.success('操作成功');
            api.keywordList({page: current, ...parameter}).then(res => {
                setData(res.list);
                setTotal(res.count);
                setloading(false)
            })
        })
    }

    return (<>
        <TableBox
            Seach={id === undefined ? Seach : null}
            total={total}
            columns={columns}
            data={data}
            current={current}
            onChange={onChange}
            loading={loading} 
        />

        <Modal
            title={<div class="keyEditTitle">批量添加<span>请用英中文逗号“,”或回车，隔离关键词</span></div>}
            destroyOnClose
            footer={null}
            closable={false}
            visible={editVisible}
            width={'65%'}
        >
            <Form
                colon={false}
                initialValues={parameter}
                onFinish={onFinishEdit}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="&#12288;关键词"
                            name="keyword"
                        >
                            <TextArea placeholder="请输入关键词" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="&#12288;排除词"
                            name="exclude"
                        >
                            <TextArea placeholder="请输入排除词" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Space style={{float: 'right'}}>
                            <Button onClick={closeEdit}>取消</Button>
                            <Button type="primary" htmlType="submit">确定</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>)
}


 
export default Keywords;